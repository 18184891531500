import React, { Component } from "react";
import Fade from "react-reveal";
import { Link } from "react-router-dom";
//import Countdown from "./UI/Countdown";
// import Form from "./Register";

class Header extends Component {
  state = {
    showModal: false,
  };
  // creating a toggle function that will be passed down to any children of this container that need to toggle the modal on or off
  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    if (!this.props.data) return null;

    const project = this.props.data.project;
    const name = this.props.data.name;
    const description = this.props.data.description;
    const organization = this.props.data.organization;
    const domain = this.props.data.domain;
    const reg_closed = this.props.data.reg_closed;
    const specs = this.props.data.specs;
    //    const bgImage = "images/ore_school_4.png";
    const loginImage = "images/elements/left.png";
    const regImage = "images/elements/right.png";
    const logoUpImage = "images/elements/logoUp.svg";
    const logoDownImage = "images/elements/logoDown.svg";
    const image90 = "images/elements/logo2.png";
    const bgImage = "images/elements/Foto.jpg";

    return (
      <section
        style={{
          backgroundColor: "#333f50",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <nav id="nav-wrap">
          <a
            className="mobile-btn"
            href="#nav-wrap"
            title="Показать навигацию"
          >
            Показать навигацию
          </a>
          <a className="mobile-btn" href="#home" title="Скрыть навигацию">
            Скрыть навигацию
          </a>

          <ul id="nav" className="nav">
            <li>
              <a href="#home">О конференции</a>
            </li>

            <li>
              <a href="#about">Контакты</a>
            </li>

            <li>
              <a href="#partners">Партнёры</a>
            </li>

            <li>
              <a href="#resume">Информация</a>
            </li>

            {/*          <li>
                <a href="#portfolio">Наши лекторы</a>
              </li>
*/}
            <li>
              <a href="#contact">Как нас найти</a>
            </li>

            <li>
              <a href="http://young-reg.tsnigri.ru/">Регистрация</a>
            </li>

            <li>
              <Link to="/materials">Материалы</Link>
            </li>
          </ul>
        </nav>

        <header id="home" style ={{height:"1308px !important"}}>
          <div className="banner"
            style={{
              backgroundColor: "#333f50",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
            }}
          >
            <div className="row">
                <div className="two columns"  style={{ padding:0, }}>
                </div>
                <div className="eight columns">
                  <img style={{width:"100%",}} src={logoUpImage}/>
                </div>
                <div className="two columns" style={{ padding:0, }}>
                  <div>
                    <img className = "logo90" style ={{padding:"30px 0 0 0",}}src={image90}/ >
                  </div>
                </div>
            </div>
            <div className="row">
                <div className="two columns" style={{padding:"10px 0",}}>
                  <a href="http://young-reg.tsnigri.ru/login">
                    <img className = "reg-login" style={{width:"100%",}} src={loginImage}/>
                  </a>                
                </div>
                <div className="eight columns">
                  <img style={{ width:"100%",}}  src={logoDownImage}/>
                </div>

                <div className="two columns" style={{padding:"10px 0",}} >
                 <a href="http://young-reg.tsnigri.ru/registratsiya">
                  <img className = "reg-login" style={{ width:"100%"}} src={regImage}/>
                 </a>
                </div>
            </div>


            {/* <div className="banner-text"> */}
              {/* <Fade bottom duration={1200}>
                <h3>{domain}</h3>
              </Fade> */}

                          
              {/* <Fade bottom duration={1200}>
                <h3>{organization}</h3>
              </Fade>

              <Fade bottom duration={1200}>
                <h2>{specs}</h2>
              </Fade>
              <Fade bottom>
                <h2 className="responsive-headline">{name}</h2>
              </Fade> */}


              {/* <Fade bottom duration={1200}>
                <h3>{description}</h3>
              </Fade> */}
              {/* <Fade bottom duration={2000}>
                <ul className="social"> */}
                  {/* <a
                    href="http://young-reg.tsnigri.ru/"
                    target="_blank"
                    rel="noreferrer"
                    className="button btn project-btn"
                  >
                    <i className="fa"></i>Регистрация
                  </a> */}
                  {/* <a
                    href={project}
                    target="_blank"
                    rel="noreferrer"
                    className="button btn project-btn"
                  >
                    <i className="fa fa-search"></i>Перейти на сайт
                  </a>*/}
                  {/* <Link to="/materials">
                    <button
                      // onClick={this.toggleModal}
                      className="button btn github-btn"
                    >
                      <i className="fa fa-folder"></i>
                      Материалы
                    </button>
                  </Link> */}
                  {/* <Link to="/form">
                    <button className="button btn github-btn">
                      <i className="fa fa-check"></i>
                      Регистрация
                    </button>
                  </Link> */}
                {/* </ul>
              </Fade> */}
              {/* <Fade bottom duration={1200}>
                <h2 >{reg_closed}</h2>
              </Fade> */}

              {/* <Fade bottom duration={2000}>
                <Countdown
                  timeTillDate="02 13 2023, 9:00 am"
                  timeFormat="MM DD YYYY, h:mm a"
                />
              </Fade> */}
            {/* </div> */}

            <div className="banner"
              style={{
                backgroundColor: "#ffe699",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "1%",
                overflow: "hidden",
                width: "100%",
                padding: "0"
              }}
            >
              <div style={{padding: "0"}}
              >
                <p style={{
                  margin: "0",
                  color: "#000",
                  "font-size":"larger",
                }}>«МИНЕРАЛЬНО-СЫРЬЕВАЯ БАЗА АЛМАЗОВ, БЛАГОРОДНЫХ И ЦВЕТНЫХ МЕТАЛЛОВ — ОТ ПРОГНОЗА К ДОБЫЧЕ»</p>
              </div>
            </div>
            <div className="banner"
              style={{
                backgroundImage: `url(${bgImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "600px",
                width: "100%",
              }}
            >
            </div>
            <div className="banner"
              style={{
                backgroundColor: "#ffe699",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                "max-height": "4px",
                overflow: "hidden",
                width: "100%",
                padding: "0",
                margin: "-27px 0 0 0"
              }}
            >
              <span>__</span>
            </div>


          </div>

          <p className="scrolldown">
            <a className="smoothscroll" href="#about">
              <i className="icon-down-circle"></i>
            </a>
          </p>

        </header>
      </section>
    );
  }
}

export default Header;
