import React, { Component } from "react";
import Slide from "react-reveal";

class Orgcom extends Component {
  render() {
    if (!this.props.data) return null;

    const constInfo = this.props.data.constInfo;

    const oreSchoolHeader = this.props.data.oreSchoolHeader;

    const variableInfoHeader = this.props.data.variableInfoHeader;

    const variableInfoFooter = this.props.data.variableInfoFooter;

    const variableInfoItems = this.props.data.variableInfoItems.map(function (variableInfoItems) {
      return (
        <div key={variableInfoItems.item}>
          <p>
            <span>&bull;&nbsp;</span>
            {variableInfoItems.item}
          </p>
        </div>
      );
    });


    const oreSchoolItems = this.props.data.oreSchoolItems.map(function (oreSchoolItems) {
      return (
        <div key={oreSchoolItems.item}>
          <p>
            <span>&bull;&nbsp;</span>
            {oreSchoolItems.item}
          </p>
        </div>
      );
    });

    const orgComChairMan = this.props.data.orgComChairMan.map(function (orgComChairMan) {
      return (
        <div key={orgComChairMan.description}>
          <div class="row"><div class="columns">{orgComChairMan.description}</div><div class="columns">{orgComChairMan.organisation}</div></div>
        </div>
      );
    });

    const orgComCoChairMan = this.props.data.orgComCoChairMan.map(function (orgComCoChairMan) {
      return (
        <div key={orgComCoChairMan.description}>
          <div class="row"><div class="columns">{orgComCoChairMan.description}</div><div class="columns">{orgComCoChairMan.organisation}</div></div>
        </div>
      );
    });

    const orgComMember = this.props.data.orgComMember.map(function (orgComMember) {
      return (
        <div key={orgComMember.description}>
          <div class="row"><div class="columns">{orgComMember.description}</div><div class="columns">{orgComMember.organisation}</div></div>
        </div>
      );
    });

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row orgcom">
            <div className="three columns header-col">
              <h1>
                <span>ПРЕДСЕДАТЕЛЬ</span>
              </h1>
            </div>
            <div className="nine columns main-col">{orgComChairMan}</div>
          </div>

          <div className="row orgcom">
            <div className="three columns header-col">
              <h1>
                <span>СО-ПРЕДСЕДАТЕЛИ</span>
              </h1>
            </div>
            <div className="nine columns main-col">{orgComCoChairMan}</div>
          </div>

          <div className="row orgcom">

            <div className="three columns header-col">
              <h1>
                <span>ОРГКОМИТЕТ</span>
              </h1>
            </div>
            <div className="nine columns main-col">{orgComMember}</div>
          </div>

          <div className="row orgcom">
            <div className="twelve columns collapsed">
              <p>{constInfo}</p>
              <b>{oreSchoolHeader}</b>
              <div className="twelve columns">{oreSchoolItems}</div>
            </div>

            <div className="twelve columns collapsed">
              <p>{variableInfoHeader}</p>
            </div>

            <div className="twelve columns">{variableInfoItems}</div>

            <div className="twelve columns collapsed">
              <p>{variableInfoFooter}</p>
            </div>

          </div>

        </Slide>
      </section>
    );
  }
}

export default Orgcom;
