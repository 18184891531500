import React, { Component } from "react";
//{/*import Zmage from "react-zmage";*/}
import Fade from "react-reveal";

let id = 0;
class Infopartners extends Component {
  render() {
    if (!this.props.data) return null;

    const partners = this.props.data.partners.map(function (partners) {
      let partnerImage = "images/info_partners/" + partners.image;
      return (
        <div key={id++}>
          <div class="row"><div class="columns partners-item-wrap">
            <a href={partners.link} ><img alt={partners.title} src={partnerImage} /></a>
          </div>

        </div>
        {/* <div className="partners-item-wrap">
         <a href={partners.link} ><img alt={partners.title} src={partnerImage} /></a>
        </div> */}
        </div>
      );
    });

    return (
      <section id="partners">
        <Fade left duration={1300} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Информационные партнеры</h1>

              <div
                id="partners-wrapper"
                className="s-bgrid-thirds cf"
              >
                {partners}
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Infopartners;
